*,
::after,
::before{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* colors */
:root{
    --Deep-green: #164B2E;
    --Light-green: #2D995F; 
    --Gray:#f2f3f3;
    --White: #ffffff;
    --Black: #343A40;
}


.finishedbodyCont{
  width: 100%;
  padding:0 2rem 0 19rem;

}
.topPadding{
  padding-top: 20px;
}
.formSecCont{
  padding: 24px 32px;
  background-color: var(--White);
  /* border-radius: 12px; */
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
 
}
.BottomButton{
  display: flex;
  flex-direction: row;
  gap: 20px;
  /* justify-content: center; */
  /* align-items: center; */
}
.formSecCont h3{
  color: #343A40;
  font-size: 20px;
  font-weight: 700;
}
.btnApprove:hover{
background-color: #164B2E;
}

.formSectionHeader{
  display: flex;
  justify-content: space-between;
}

.sec1{
  font-size: 12px;
  color:#6C757D;
  text-align: end;
}

.sec2{
  font-size: 20px;
  font-weight: bold;
  color:#2D995F;
}

.shadow {
  box-shadow: 0 0 5px rgb(238, 236, 236);
}

.applycntnt {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 100px;
}

.loandethead{
  display: flex;
  align-self: center;
  gap:10px;
  margin-bottom: 34px;
  margin-left: 42px;
  margin-top: 40px;
  cursor: pointer;
}

.loandethead p{
  justify-content: center;
  align-items: center;
}

.loandethead button{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  color: var(--Black);
  font-size: 16px;
  gap: 5px;
  border: none;
}
.loandethead button:hover{
  /* background-color: var(--Light-green); */
  color: var(--Light-green);
  background-color: transparent;
  border: 1px solid var(--Light-green);
}

.loanText {
  font-size: 15px;
  color:#6C757D;
}

.fetchText{
  font-size: 15px;
  color: rgba(45, 153, 95, 1);
  font-weight: 500;
}

.theamount{
  font-size: 20px;
  font-weight: 700;
  color: rgba(45, 153, 95, 1);
  margin-left: 30px;
}

.monthText{
  font-size: 18px;
  font-weight: 700;
  color:rgba(108, 117, 125, 1);
  margin-left: 65px;
}

.loanContainer{
  margin-top: 15px;
  overflow-x: auto;
  margin-left: 72px;
  margin-right: 30px;
}

.loanContainer h4{
  font-size: 36px;
  font-weight: bold;
  color:#343A40;
  margin-bottom:18px;
}

.loanContainer p{
  font-size: 14px;
  color: #6C757D;
  
}

.loanContainer img{
  margin-bottom: 25px;
}

.firstP {
  margin-bottom:25px
}

.secondP{
  margin-bottom: 24px;
}

.thirdP{
  margin-bottom: 58px;
}

.btnEdit{
  background-color: #2D995F;
  display: inline-block;
  border-radius: 4px;
  margin-bottom: 44px;
}
.btnApprove{
  background-color: #2D995F;
  display: inline-block;
  border-radius: 4px;
  margin-bottom: 44px;
}
.btnApprove1{
  background-color: rgb(126, 8, 8);
  display: inline-block;
  border-radius: 4px;
  margin-bottom: 44px;
}
.btnApprove1:hover{
  background-color: rgb(212, 47, 47);
}

.btnEdit button{
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 108px;
  padding-right: 108px;
}
.btnApprove1 button{
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 108px;
  padding-right: 108px;
}
.btnApprove button{
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 108px;
  padding-right: 108px;
}

.loanTable {
 color: black;
 background-color: rgba(233, 236, 239, 1);
 height: 50px;
 font-weight: normal;
 white-space: nowrap;
 /* padding: 30px; */
 text-align: center;
}
/* .loanResponsive {
overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
} */

/* .tableText{
font-size: 15px;
font-weight: normal;

} */
.loanResponsive table th{
  background-color: var(--Light-green);
  color: white;
}
.loanResponsive table > td, th{
  border: none;
}
 .loanResponsive .edit{
    color: var(--Light-green);
 }
 .loanResponsive .accrodBtns{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: var(--Light-green);
 }
 .loanResponsive .accrodBtns .prev{
  border: 1px solid #2D995F;
  color: var(--Light-green);
  background-color: transparent;
  padding: 5px 20px;
 }
 .loanResponsive .accrodBtns .next{
    padding: 5px 20px;
 }
/* .loanResponsive .edit:hover{
  border: 1px solid var(--Light-green);
  background-color: var(--Light-green);
  color: var(--White);
  border: 10px;
} */

.imgapply img {
  width: 170px;
  height: 165px;
}

.applygrnttxt {
  font-family: 'inter';
  color: #2D995F;
  font-weight: 700;
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.grntapplytxt {
  color:rgba(108, 117, 125, 1);
  font-weight: 400;
  font-size: 16px;
}

.applyLoan{
  width: 300px;
  height: 55px;
  background-color: #2D995F;
  color: white;
  margin-top: 30px;
  cursor: pointer;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.applyLoan:hover{
  background-color: #46a874;
}

.continueReg{
  color: white;
  font-size: 16px;
  font-weight: 700;
text-align: center;


}

.regContainer{
    display: grid;
    grid-template-columns: 25% 75%;
    /* gap: 30px; */
}
.sideNav{
    background-color: var(--Deep-green);
    padding: 37px 0 0 21px;
    height: 1800px;
    max-width: 339px;
}
.regMenu{
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.regMenu a{
    text-decoration: none;
}
/* .regMenu a:active{
    background: linear-gradientrgba((#2D997F) 100%, (#2D997F) 31%);
} */
.regMenu p{
    color: var(--White);
    font-size: 18px;
    font-weight: 400;
    margin:0;
}
.regMenu .active{
    background: linear-gradient9(#2D997F) (#2D997F)0;
}
.formSection{
    padding: 35px 30px;
}
.formSection p{
    font-size: 14px;
}
.formSection h1{
    color: var(--Light-green);
    font-size: 28px;
    font-weight: 700;
}
.formSecCont{
    padding: 24px 32px;
    background-color: var(--White);
    /* border-radius: 12px; */
   
}
.formSecCont h3{
    color: #343A40;
    font-size: 20px;
    font-weight: 700;
}
.mainform{
    width: 100%;
    background-color: var(--White);
    border-radius: 12px;
    margin-top: 26px;
    display: flex;
    flex-direction: column;
}
@media(max-width:800px){
  .finishedbodyCont{
    padding-left: 0;
    padding-right: 0;
  
  }
  .formSecCont{
    padding: 21px 25px;
    background-color: var(--White);
    border-radius: 0;
    margin-bottom: 20px;
   
  }
  .formSecCont h3{
    color: #343A40;
    font-size: 20px;
    font-weight: lighter;
  }
  .applygrnttxt {
    font-size: 18px;
    margin-top: 17px;
    margin-bottom: 4px;
  }
  
  .grntapplytxt {
    padding: 0 34px;
    font-size: 14px;
  }
  .applyLoan{
    width: 236px;
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-bottom: 0;
  }
  
  .continueReg{
    /* color: white; */
    /* font-size: 16px; */
    font-weight: 400;
  /* text-align: center; */
  }
}
  
  
