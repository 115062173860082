*,
::after,
::before{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* colors */
:root{
    --Deep-green: #164B2E;
    --Light-green: #2D995F; 
    --Gray:#f2f3f3;
    --White: #ffffff;
    --Black: #343A40;
    /* --Background:rgba(172, 231, 199, 0.05); */
}

.accordionCard{
    border: none;
    color: var(--White);
}
.accordionCard button{
    padding: 10px 10px 0 0 !important;
    width: 100%;
    
}
.cardHeader{
    background-color: #164B2E;
    color: var(--White);
    border: none;
    padding: 5px 7px 5px 0;
    font-size: 16px !important;
    font-weight: lighter !important;
}
.cardHeader .padding{
    /* margin: 20%; */
}
.cardBody a{
    padding:  0 !important;
    color: var(--White);
    /* overflow-y: auto; */
    font-size: 14px;
    /* margin: 10px 0; */
}
.cardBody{
    background-color: var(--Deep-green);
    color: var(--White);
    padding: 5px 0 ;
    /* border-left: 1px solid var(--White); */
    margin-left: 60px;
}
.imageCont{
    height: 100px;
    width: 100px;
    margin-top: 10px;
}
.cardBody::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
    display: none;
}
.cardBody::-webkit-scrollbar-thumb {
    background: #2D995F; /* Color of the scrollbar handle */
    border-radius: 4px; /* Rounded corners of the scrollbar handle */
}
.cardBody::-webkit-scrollbar-thumb:hover {
    background: #037538; /* Color of the scrollbar handle on hover */
}


.logoCont{
    border-bottom: 2px solid #E9ECEF;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 10px; */
    flex-direction: column;
}
.logoCont p{
    font-size: 16px;
    font-weight: 700;
    /* padding: 45px 32px 37px 32px; */
}
.img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.sideNavBody{
    /* max-width: 367px; */
}
.regContainer{
    display: flex;
    justify-content: space-between;
    /* grid-template-columns: 25% 75%; */
    /* gap: 30px; */
}
.sideNav{
    background-color: var(--Deep-green);
    height: 1800px;
    position: fixed;
}

.menuCont {
    padding: 37px 27px 0 24px;
    overflow-y: auto; /* Enable vertical scrolling */
    /* max-height: calc(100vh - 74px); Adjust this value based on your layout */
    overflow-y: auto;
    max-height: calc(100vh - 200px);
}

/* Hide the scrollbar */
.menuCont::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
    /* display: none; Hide scrollbar */
}

.menuCont::-webkit-scrollbar-thumb {
    background: var(--Light-green); /* Color of the scrollbar handle */
    border-radius: 4px; /* Rounded corners of the scrollbar handle */
}

/* .menuCont::-webkit-scrollbar-thumb:hover {
    background: #037538; 
} */

.regMenu{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.regMenu a{
    text-decoration: none;
    padding: 12px 34px 12px 20px;
}
.regMenu .active {
    background: linear-gradient(to right, #4b9483, #2d998050);
    border-radius: 4px;
}
.regMenu img{
  width: 20px;
  height: 20px;
  margin-right: 20px;
}
.regMenu p{
    color: var(--White);
    font-size: 14px;
    font-weight: 400;
    margin:0;
    /* text-align:cen; */
}

.formSection{
    width: 100%;
    padding: 0 5rem 0 20rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.formSectionHeader{
  /* width: 100%; */
  padding-top: 40px;
  margin-bottom: 10px;
}
.formSection p{
    font-size: 14px;
}
.formSection h3{
    color: var(--Black);
    font-size: 18px;
    font-weight: 600;
}
.formSecCont{
    padding: 24px 32px;
    background-color: var(--White);
    border-radius: 12px;
    
    width: 100%;
}
.shadow{
  box-shadow: 5px 5px 10px var(--Gray) ;
}
.formSecCont h3{
    color: #343A40;
    font-size: 20px;
    font-weight: 700;
}

.mobileshow{
    display: none;
}
.harmborgers{
    display: none;
}
.harmborgers i{
    font-size: 24px;
}
    @media(max-width:800px){

        .mobileshow{
            display: block;
        }
        .webshow{
            display: none;
        }
        .harmborgers{
            margin-right: 20px;
            display: block;
        }
        /* .menuOpen{
            display: none;
        } */
        .logoCont{
            width: 100%;
            max-width: 122px;
            height: 45px;
            padding-left: 20px;
            /* overflow: hidden; */
            margin-bottom: 0;
        }
        .img{
            /* width: 100%; */
            /* height: 100%; */
            object-fit: contain;
        }
        
        .sideNavBody{
            /* width: 100%; */
        }
        .sideNav{
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: var(--White);
            padding:0;
            height: 74px;
            width: 100%;
        }
        .regMenu{
            display: flex;
            flex-direction: column;
            width: 100%;
            background-color: var(--White);
            /* justify-content: space-between; */
            align-items: left;
            top: 80px;
            box-shadow: 0 -1px 1px rgb(226, 224, 224);
            padding: 27px;
            gap: 30px;
            position: absolute;
            /* display: none; */
        }
        .menuOpen{
            display: none;
        }
    
        .regMenu a{
            text-decoration: none;
            padding: 0;
        }
        .regMenu .active {
            background: transparent;
            border-radius: 0;
            color: var(--Light-green);
        }
        .regMenu img{
          width: 20px;
          height: 20px;
          margin-right: 20px;
        }
        .regMenu p{
            color: var(--Black);
            font-size: 16px;
            font-weight: 400;
            margin:0;
            display: flex;
            justify-content: start;
            align-items: center;
        }
        
        .formSection{
            width: 100%;
            padding: 50px 0 0 25px;
        }
        .formSectionHeader{
          width: 100%;
          padding-top: 40px;
          margin-bottom: 10px;
        }
        .formSection p{
            font-size: 12px;
        }
        .formSection h1{
            /* color: var(--Light-green); */
            font-size: 18px;
            /* font-weight: 700; */
        }
        .formSecCont{
            padding: 24px 32px;
            background-color: var(--White);
            border-radius: 12px;
            
            width: 100%;
        }
        .shadow{
          box-shadow: 5px 5px 10px var(--Gray) ;
        }
        .formSecCont h3{
            color: #343A40;
            font-size: 20px;
            font-weight: 700;
        }
        
        
    }

