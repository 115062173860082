*,
::after,
::before{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
/* colors */
:root{
    --white:  #ffffff;
    --light-blue:#DDF3E7;
    --green:#2D995F;
    --text:#343A40;
    --h-tags:#000000;
}
.body{
    width: 100%;
    overflow: hidden;
}
.img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

p{
    font-size: 20px;
    color: var(--text);
}
h1, h2, h3, h4, h5, h6{
    color: var(--h-tags);
}
button{
    background-color: transparent;
    border: none;
    outline: none;
    border-radius: 4px;
}
.greenBtn{
    padding: 20px 60px;
    background-color: var(--green);
    color: white;
    font-weight: 700;
    transition: ease 0.3s;
    border-radius: 4px;
   
}

.greenBtn1{
    padding: 20px 60px;
    background-color: var(--green);
    color: white;
    font-weight: 700;
    transition: ease 0.3s;
    border-radius: 4px;
    margin-top: 30px;
}
.greenBtn:hover{
    background-color: #87c5a4;
    /* border: 1px solid #87c5a4; */
}
.topSpace{
    padding-bottom: 5rem;
}
.container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.faqsContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.contentContainer{
    width: 100%;
    padding: 0 100px;
    margin-bottom: 30px;
    /* max-width: 1000px;    */
}
.section1{
    background-image: url('../../Images/grouped.svg');
    width: 100%;
    max-width: 1728px;
    background-position-x: -100px;
    /* height: 756px; */
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 2rem;
    padding-bottom: 50px;
    position: relative;
}
.heroContent{
    width: 100%;
    max-width: 676px;
    text-align: left;
    margin-top: 174px;
    padding: 0 0 0 86px;
}
.heroContent h1{
    font-size: 54px;
    font-weight: 900;
}
.heroContent h1 span{
    color: var(--green);
}
.heroContent button{
    margin: 44px 0 0 0;
    /* margin-bottom: 2rem !important; */
}
.section2{
    width: 100%;
    background-image: url('../../Images/grouped2.svg');
    height: 400px;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 13px;
}

.section3 .sellerImg{
    width: 640px;
    height: 843px;
    overflow: hidden;
    margin-top: 50px;

}

.section3 {
display: flex;
justify-content: center;
align-items: center;
}
.sellerImg{
    width: 50%;
}

.sellersContent{
    width: 50%;
    margin-top: 40px;
}

.section3 .sellersContent{
    /* margin-top: 150px; */
    margin-left: 60px;
    
}

.section3 .sellersContent h1{
    font-weight: 800;
    font-size: 48;
    color: #000000;
}

.newsContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 120px;
}
.section3 .sellersContent p:nth-child(3){
    margin-top: 20px;
}

.newsHeader{
    font-weight: 700;
    font-size: 48;
}

.sellerText{
    font-size: 18px;
}

.happeningText{
    margin-top: 16px;
}
.accordionContainer{
    margin-top: 66px;
    width: 100%;
    max-width: 900px;
    margin-bottom: 30px;
}

.accordionText{
    font-size: 15px !important;
    font-weight: 400 !important;
}


.faqs{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin-top: 50px; */
background: rgba(233, 236, 239, 0.33);
}

.faqsContainer{
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(233, 236, 239, 0.33);
    /* margin-bottom: 30px; */
}


.faqsHeader{
    font-weight: 700;
    font-size: 48;
    margin-top: 20px;
    color: #000000;
}

.accordionSpace{
margin-top: 30px;
}

@media(max-width:800px){
    p{font-size: 12px;}

    h1{font-size: 24px;}

    /* .container{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    } */
    /* .faqsContainer{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    } */
    .contentContainer{
        width: 100%;
        padding: 0 22px;
        /* margin-bottom: 30px; */
        /* max-width: 1000px;    */
    }
    .dapoSection{
        padding:0 0 0 25px;

    }
    .section1{
        background-image: url('../../Images/LandingPageMobileBg.svg');
        width: 100%;
        max-width: 100%;
        background-position-x: -25px;
        height: 691px;
        background-size: cover;
        margin-top: 0;
    }
    .heroContent{
        margin-top: 400px;
        padding: 0 0 0 23px;
    }
    .heroContent h1{
        font-size: 24px;
        font-weight: bold;
    }
    .greenBtn{
        padding: 16px 32px;
        font-weight: 900;
    }
    .greenBtn1{
        padding: 16px 60px;
    }
    
    .heroContent button{
        margin: 28px 0 0 0;
    }

    /* section 2 */
    .section2{
        width: 100%;
        background-image: url('../../Images/DapoLandingMobileBg.svg');
        height: 234px;
        background-size: contain;
    }
    
    .section3 .sellerImg{
        width: 100%;
        max-width: 381px;
        height: 490px;
        margin-top: 70px;
    }
    .section3 {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items:center;
    }
    .sellersContent{
        width: 100%;
    }
    .section3 .sellersContent{
        margin-top: 52px;
        margin-left: 0;
    }  
    .section3 .sellersContent h1{
        font-weight: bold;
        font-size: 20;
        padding-bottom: 20px;
    }
    .section3 .sellersContent p:nth-child(3){
        margin-top: 20px;
    }
    .sellerText{
        font-size: 12px;
    }
    .happeningText{
        margin-top: 16px;
    }
    .accordionContainer{
        margin-top: 44px;
        max-width: 100%;
        padding: 0 15px;
    }
    
    .faqsContainer{
        padding: 0;
        margin-bottom: 30px;
    }
    .faqsHeader{
        font-weight: bold;
        font-size: 20;
        margin-top: 40px;
    }
    .accordionSpace{
        margin-top: 24px;
    }
    .accordion-button{
        font-size: 10px !important;
    }
    


    
    

}