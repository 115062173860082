body {
    background-color: #E9ECEF !important;
}
*,
::after,
::before{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.makePayBtn{
    background-color: #2D995F;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    border: none;
    padding: 16px 24px 16px 24px;
    
}
.undrsub{
    font-size: 14px;
    font-weight: 400;
    /* white-space: normal; */
    /* display: flex; */
}
.undrsub .dateTxt{
    font-weight: 600;
}
.welcome{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
/* .userName{
    color: #2D995F !important;
} */
.cmpVer{
    font-size: 14px;
    font-weight: 600;
    color: #2D995F;
    margin-top: 10px;
}
.sumitTxt{
    font-size: 16px;
    font-weight: 600;
    color:#101928 ;
}
.makePayBtn1{
    background-color:#2D995F;
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
    border: none;
    /* padding: 16px 24px 16px 24px; */
    padding: none;
    border-radius: 3px!important;
}
.headerPaymentTxt{
    font-size: 12px;
    color:#98A2B3;
    font-weight: 500;
}

/* colors */
:root{
    --Deep-green: #164B2E;
    --Light-green: #2D995F; 
    --Gray:#f2f3f3;
    --White: #ffffff;
    --Black: #343A40;
    --Black: #343A40;
    --Background:rgba(172, 231, 199, 0.05);
}
.regBody{
    width: 100%;
    /* height: 100vh; */
    /* background-color: var(--Gray); */
}
.img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.finishedbodyCont{
    width: 100%;
    padding: 0 2rem 0 19rem;
    overflow: hidden;
}

.mainform{
    width: 100%;
    background-color: var(--White);
    padding: 20px 20px 4rem 20px;
    margin-top: 26px;
    display: flex;
    flex-direction: column;
}
.headerCards{
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 30px;
    /* justify-content: space-between; */
    align-items: center;
}
.figAmtCon{
    display: flex;
    white-space: nowrap;
    font-size: 32px;
    color: #ffffff;
    font-weight: 600;
}

.balanceFig{
    font-size: 12px;
    font-weight: 400;
    white-space: nowrap;
    display: flex;
}
.figure{
    font-size: 24px;
    margin-top: 5px;
    color:#98A2B3;
    font-weight: 500;
}
.fig{
    color: #ffffff;
}
.headerCard1{
    /* background-color: var(--Deep -green); */
    /* background-color: rgba(249, 249, 249, 0.2); */
    background-color: black;
    color: var(--White);
}
.thedi{
    display: flex;
    gap: 10px;
    /* font-size: 14px; */
    font-weight: 400;
    color: #000000!important;
}
.toScool{
    font-size: 16px;
    color: #000000;
    font-weight: 400;
}
.tfTxt{
    font-size: 14px;
    margin-top: -15px;
}
.driz{
    display: flex;
    gap: 20px;
    white-space: nowrap;
}
.verfIcon{
    width: 115px;
    height: 118.91px;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 15px;
}
.falR{
    width: 40px;
    height: 40px;
}
.seeAll{
    color: #EB5017;
    margin-right: 10%;
}
.headerCard2{
    background-color: #fbeae9;
    color: var(--Black);
    border: 2px solid #f6d6d4;
    display: flex;
    justify-content: space-between;
    position: relative;
}
.amtt{
    font-size: 18px;
    font-weight: 600;
    color:  #000000;
}
.loandgrantcards{
    display: grid;
    grid-template-columns: 2fr 1fr;
    width: 100%;
    gap: 20px;
}
.headerCards .card span{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.loandcards{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    width: 100%;
    margin-top: 20px;
}
.card{
    /* background-color: rgba(0, 125, 215, 0.2); */
    /* padding: 20px 10px 20px 10px ; */
    padding:  20px;
    width: 300px;
    height: 150px;
    /* margin-top: 10px; */
    border-radius: 10px;
    cursor: pointer;
}


.card1 {
    background-color: #fef6e7;
    width: 200px;
    height: 240px
}
.card1:hover {
    transform: scale(1.1);
    box-shadow: 3px 5px 5px #34B16E17;
  }
.card2 {
    background-color: #ffece5;
    width: 200px;
    height: 240px
}
.card2:hover {
    transform: scale(1.1);
    box-shadow: 3px 5px 5px #34B16E17;
  }
.card3 {
    background-color:  rgba(69, 122, 20, 0.089);
    width: 200px;
    height: 240px
}
.card3:hover {
    transform: scale(1.1);
    box-shadow: 3px 5px 5px #34B16E17;
  }
.card4 {
    /* background-color: rgba(69, 122, 20, 0.21); */
        background-color: #E3EFFC;
        width: 200px;
        height: 240px
}
.card4:hover {
    transform: scale(1.1);
    box-shadow: 3px 5px 5px #34B16E17;
  }
.card5 {
    background-color: #fbeae9;
    color: var(--Black);
    border: 1px solid #f6d6d4;
    grid-column: 1/5;
    display: flex;
    position: relative;
    height: 200px;
}

.RightContents{
    width: 100%;
}
.rightContHead{
    display: flex;
    justify-content:space-between;
    align-items: center;
    width: 100%;
}
.subRightContents{
    box-shadow: -1px 1px 1px #343a403a;
    /* height: 85%; */
    margin-top: 5px;
    border-radius: 8px;
    /* padding: 20px; */
    border: 1px solid #ADB5BD;
    width: 95%;
    
}




.regMenu p{
    color: var(--White);
    font-size: 18px;
    font-weight: 400;
    margin:0;
}
.regMenu .active{
    background: linear-gradient9(#2D997F) (#2D997F)0;
}
.topSpace{
    padding-top: 20px;
}
.formSection{
    padding: 35px 30px;
    width: 100%;
}
.formSecCont{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 35px 30px; */
    /* background-color: gray; */
}
.formSecCont button{
    padding: 9px 20px;
}
.formSection p{
    font-size: 14px;
}
.formSection h1{
    color: var(--Light-green);
    font-size: 28px;
    font-weight: 700;
}

.formSecCont{
    padding: 24px 32px;
    background-color: var(--White);
    /* border-radius: 12px; */
   
}
.formSecCont h3{
    color: #343A40;
    font-size: 20px;
    font-weight: 700;
    
}

/* .shadow {
    box-shadow: 0 0 5px rgb(238, 236, 236);
} */

.finishedbody{
    background-color: var(--White);
    border-radius: 12px;
    margin-top: 26px;
    display: flex;
    flex-direction: column;
    /* padding-left: 20rem; */   
}

body {
    font-family: 'Inter', sans-serif;
  }
  
  
  .stId{
  
    font-size: 14px;
    padding-bottom: 12px;
    color: #6C757D;
    
    }

    .signin{
        margin-top: 39px;
        margin-left: 19px;
    }
  
  .rszeInput {
    display: flex;
    gap: 70px;
    margin-left: 20px;
    /* justify-content: space-between; */
  }
  
  .formInput{
    margin-top: 30px;
    background-color: #fff !important;
    display: flex;
    flex-direction: column;
  }
  
  .snInput {
    border: 1px solid #ADB5BD;
    border-radius: 4px;
    height: 55px;
      width: 400px;
    margin-bottom: 5px;
    /* padding-left: 10px; */
    outline: none;
    background-color: #f7f8fa !important;
    padding: 10px;
  }
  .snInput2 {
    border: 1px solid #ADB5BD;
    border-radius: 4px;
    height: 148px;
      width: 400px;
    margin-bottom: 5px;
    /* padding-left: 10px; */
    outline: none;
    background-color: #f7f8fa !important;
    padding: 10px;
  }
  .snInput23 {
    border: 1px solid #ADB5BD;
    border-radius: 4px;
    height: 148px;
      width: 400px;
    margin-bottom: 5px;
    /* padding-left: 10px; */
    outline: none;
    background-color: #f7f8fa !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
  }
  
  .formInput1 {
    display: flex;
    flex-direction: column;
  }
  
  .fileContainer {
    display: flex;
    align-items: center;
    cursor: pointer !important;
  }
  
  .fileInputLabel {
    position: relative;
    display: inline-block;
    cursor: pointer !important;
  }
  
  .fileInput {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer !important;
    
  }
  
  .fileIcon {
    width: 24px; /* Adjust as needed */
    height: 24px; /* Adjust as needed */
    margin-right: 8px; /* Adjust as needed */
    cursor: pointer !important;
  }
  
  .placeholder {
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
  padding-bottom: 12px;
  color: #6C757D;
  }
  
  
  .nextKin{
    margin-top: 50px;
    color: #2D995F;
    font-weight: 700;
    font-size: 20px;
    margin-left: 20px;
  }
  
  .continueButton{
    width: 400px;
    height: 55px;
    background-color: #2D995F;
    color: white;
    margin-top: 30px;
    cursor: pointer;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 66px;
    margin-left: 20px;
    margin-top: 57px;
  }
  
  .continueReg{
    color: white;
    font-size: 16px;
    font-weight: 700;
  text-align: center;
  
  
  }
  
  
  

.welcome {
    font-family: 'inter';
    color: #2D995F;
    font-size: 28px;
    font-weight: 700;
}

.welcomeintro {
    font-size: 14px;
    font-weight: 400;
    color: #6C757D;
    margin-top: -9px;
}

.dashtext {
    margin-top: 26px;
    margin-bottom: 26px;
    width: 1218px;
    height: 97px;
    background-color: white;
    box-shadow: rgba(0.059, 0.059, 0.059, 0.059) 0px 10px 50px;
    border-radius: 12px;
}

.dashtext p {
    font-size: 24px;
    font-family: 'inter';
    color: #343a40;
    font-weight: 700;
    padding: 34px;
}

.loandetails {
    background-color: white;
    border-radius: 12px;
    box-shadow: rgba(0.059, 0.059, 0.059, 0.059) 0px 10px 50px;
    width: 1218px;
    height: 1408px;
}

.loancard {
    /* width: 370px; */
    /* height: 300px; */
    background-color: rgba(227, 191, 0, 0.2);
    padding: 30px;
    margin-top: 10px;
    border-radius: 8px;
    margin-left: 5px;
    cursor: pointer;
}


.loancardgreen {
    /* width: 370px; */
    /* height: 300px; */
    background-color: rgba(69, 122, 20, 0.21);
    padding: 30px;
    margin-top: 10px;
    border-radius: 8px;
}
.icon01{
    width: 24.65px;
    height: 24.65px;
}
.icon02{
    width: 28.33px;
    height: 29.29px;
}
.icon03{
    width: 24px;
    height: 28.85px;
}
.icon04{
    width: 24px;
    height: 28.85px;
}

.icons {
    width: 36px;
    height: 36px;
    /* margin-bottom: 10px; */
    /* margin-top: 20px; */
    /* margin-left: 20px; */
}

.loancard p {
    font-weight: 700;
    font-size: 36px;
    margin-bottom: 5px;
}

.loancardblue p {
    font-weight: 700;
    font-size: 36px;
    margin-bottom: 5px;
}

.loancardgreen p {
    font-weight: 700;
    font-size: 36px;
    margin-bottom: 5px;
}

.pdisc {
    font-weight: 400 !important;
    font-size: 15px !important;
    margin-top: 6px;
    margin-left: 20px;
    color: #343A40 !important;
}
.about{
    font-size: 14px;
    font-weight: 400;
    color:#1D2739;
    margin-top: -10px;
}

.applyText {
    font-weight: 600 !important;
    font-size: 16px !important;
    margin-top: 25px;
    /* margin-left: 20px; */
    white-space: nowrap;
    color: #1D2739 !important;
}


.userdetails {
    font-size: 20px;
    font-weight: 700;
    margin: 34px 34px 0 34px;
    color: #343A40 !important;
}

.lineBottom {
    width: 186px;
    height: 4px;
    margin-left: 34px;
    border: 2px solid #2D995F;
    margin-top: 4px;
}

.userdetailsbnk {
    font-size: 20px;
    font-weight: 700;
    margin: 34px 34px 0 0;
    color: #343A40 !important;
}

.lineBottombnk {
    width: 186px;
    height: 4px;
    margin-right: 34px;
    margin-right: 187px;
    border: 3px solid #2D995F;
    margin-top: 4px;
}

.thedetails {
    background-color: #F2F5F3;
    width: 93%;
    /* height: 377px; */
    border-radius: 8px;
    margin-left: 34px;
    margin-top: 28px;
    margin-bottom: 20px;
    
}
.thedetailsbiz {
    background-color: #F2F5F3;
    width: 50%;
    /* height: 377px; */
    border-radius: 8px;
    margin-left: 34px;
    margin-top: 28px;
    margin-bottom: 20px;
}
.thedetailsbiz2 {
    background-color: #F2F5F3;
    width: 30%;
    /* height: 377px; */
    border-radius: 8px;
    margin-left: 34px;
    margin-top: 28px;
    margin-bottom: 20px;
}

.firstcard {
    background-color: white;
    max-width: 262px;
    height: 325px;
    width: 500px;
    border-radius: 4px;
    border: 1px solid #CED4DA;
    margin-left: 10px;
   
    
}

table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

th {
    /* background-color: #f2f2f2; */
}

td {
    border-bottom: 1px solid #ddd;
    padding: 8px;
}

.header {
    font-size: 14px;
    font-weight: 400;
   
}

.body {
    margin-top: 5px;
    font-size: 15px ;
    color: rgba(52, 58, 64, 1);
    font-weight: 700;
}

.last {
    border-bottom: none; /* Remove border for the last cell */
}

.firstcard hr {
    /* border: 1px solid #CED4DA; */
    margin-top: 8px;
}
.detailscards {
 
    padding: 26px;
   
  
}

.labelinfo {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 4px;
    padding-left: 14px;
    padding-top: 10px;

}

.infouser {
    font-weight: 500;
    font-size: 14.5px;
    padding-left: 14px;
    border-bottom: 1px solid #CED4DA;
    color: #343A40 !important;
}

.labelinfo2 {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 4px;
    padding-left: 14px;
    padding-top: -10px;
    
}

.labelinfo3 {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 4px;
    padding-left: 14px;
    padding-bottom: 3px;
    /* padding-top: -10px; */
}
.labelinfo4 {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 4px;
    padding-left: 14px;
    padding-top: -10px;
}

.infouser4 {
    font-weight: 500;
    font-size: 14.5px;
    padding-left: 14px;
    color: #343A40 !important;
    /* border-bottom: 1px solid #CED4DA; */
}
.infouser3 {
    font-weight: 500;
    font-size: 16px;
    padding-left: 14px;
  
    /* border-bottom: 1px solid #CED4DA; */
}
.infouser2 {
    font-weight: 500;
    font-size: 16px;
    padding-left: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #2D995F;
    text-align: center;
    /* border-bottom: 1px solid #CED4DA; */
}

.labelinfo {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 4px;
    padding-left: 14px;
    padding-top: -10px;
}

.detailscards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    width: 100%;
    justify-content: space-between;
}

.busnisessdetails {
    display: flex;
    /* width: 100%; */
}

.headfordetails {
    display: flex;
    justify-content: space-between;
}

.wholebizdetails {
    overflow: hidden;
    width: 100%;
}

.thedetailsbiz  .detailscardsbiz{
    /* width: 100%; */
}
.thedetailsbiz2  .detailscardsbiz2{
    /* width: 100%; */
}

.detailscardsbiz2 {
    margin: 5px;
    padding: 26px;
    /* margin-top: 26px; */
    gap: 15px;
}
.detailscardsbiz {
    margin: 5px;
    padding: 26px;
    /* margin-top: 26px; */
    gap: 15px;
}

.labelinfobiz {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 4px;
    padding-left: 14px;
    padding-top: 10px;

}

.infouserbiz {
    font-weight: 500;
    font-size: 14.5px;
    padding-left: 14px;
    border-bottom: 1px solid #CED4DA;
    color: #343A40 !important;
}

.labelinfo2biz {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 4px;
    padding-left: 14px;
    padding-top: -10px;
    
}

.labelinfo3biz {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 4px;
    padding-left: 14px;
    padding-bottom: 3px;
    /* padding-top: -10px; */
}
.labelinfo4biz {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 4px;
    padding-left: 14px;
    padding-top: -10px;
}

.infouser4biz {
    font-weight: 500;
    font-size: 14.5px;
    padding-left: 14px;
    color: #343A40 !important;
    /* border-bottom: 1px solid #CED4DA; */
}
.infouser3biz {
    font-weight: 500;
    font-size: 16px;
    padding-left: 14px;
  
    /* border-bottom: 1px solid #CED4DA; */
}
.infouser2biz {
    font-weight: 500;
    font-size: 16px;
    padding-left: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #2D995F;
    border-bottom: 1px solid #CED4DA;
}

.labelinfobiz {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 4px;
    padding-left: 14px;
    padding-top: -10px;
}

.detailscardsbiz {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.detailscardsbiz2 {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.firstcardbiz {
    background-color: white;
    width: 262px;
    height: 325px;
    border-radius: 4px;
    border: 1px solid #CED4DA;
    
}

.firstcardbiz hr {
    /* border: 1px solid #CED4DA; */
    margin-top: 8px;
}


@media (max-width: 667px) {
    .sideNavBody {
      display: none;
    }

    .finishedbodyCont {
        padding-left: 1rem;
        padding-right: 1rem;
       
    }

    .formSecCont h3{
        color: #343A40;
        font-size: 16px;
        font-weight: 400;
        
    }

    .loandgrantcards {
        display: table-column;
        padding: 0px;
       
    }

    .loancard {
        /* width: 333px; */
        /* height: 336px; */
        padding: 30px;
        margin-top: 20px;
        margin-bottom: 20px;
        
    }
    .loancardblue {
        /* width: 333px; */
        /* height: 336px; */
        padding: 30px;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 5px;
        
    }
    .loancardgreen {
        /* width: 333px; */
        /* height: 336px; */
        padding: 30px;
        margin-top: 20px;
        margin-left: 5px;
       
    }

    .detailscards {
        display: flex;
        flex-direction: column;
        gap: 23px;
        margin-top: 5px;
        margin: 0px;
        
    }

    .thedetails {
        margin-left: 5px;
       
    }

    .userdetails {
        margin-left: 5px;
    }

    .lineBottom {
        width: 178px;
        margin-left: 5px;
    }

    .detailscardsbiz {
        display: flex;
        flex-direction: column;
        gap: 23px;
        margin-top: 5px;
        margin: 0px;
    }

    .thedetailsbiz {
        margin-left: 5px;
        width: 97%;
    }

    .firstcard  {
        max-width: 100%;
        margin-left: 1px;
    }
    .firstcardbiz  {
        width: 100%;
    }
  }

  @media (max-width: 667px) {
    .loancard {
        width: 346px;
    }
    .loancardblue {
        width: 346px;
    }
    .loancardgreen {
        width: 346px;
    }
  }

  @media (max-width: 932px) {
    .loancard {
        width: 387px;
    }
    .loancardblue {
        width: 387px;
    }
    .loancardgreen {
        width: 387px;
    }
  }
  @media (max-width: 915px) {
    .loancard {
        width: 368px;
    }
    .loancardblue {
        width: 368px;
    }
    .loancardgreen {
        width: 368px;
    }
  }

  @media (max-width: 991px) {
    .sideNavBody {
      display: none;
    }

    .finishedbodyCont {
        padding-left: 1rem;
        padding-right: 1rem;
       
    }

    .formSecCont h3{
        color: #343A40;
        font-size: 16px;
        font-weight: 400;
        
    }

    .loandgrantcards {
        display: table-column;
        padding: 0px;
       
    }

    .loancard {
        width: 348px;
        height: 336px;
        padding: 30px;
        margin-top: 20px;
        margin-bottom: 20px;
        
    }
    .loancardblue {
        width: 348px;
        height: 336px;
        padding: 30px;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 5px;
        
    }
    .loancardgreen {
        width: 348px;
        height: 336px;
        padding: 30px;
        margin-top: 20px;
        margin-left: 5px;
       
    }

    .detailscards {
        display: flex;
        flex-direction: column;
        gap: 23px;
        margin-top: 5px;
        margin: 0px;
        
    }

    .thedetails {
        margin-left: 5px;
       
    }

    .userdetails {
        margin-left: 5px;
    }

    .lineBottom {
        width: 178px;
        margin-left: 5px;
    }

    .detailscardsbiz {
        display: flex;
        flex-direction: column;
        gap: 23px;
        margin-top: 5px;
        margin: 0px;
    }

    .thedetailsbiz {
        margin-left: 5px;
        width: 97%;
    }

    .firstcard  {
        max-width: 100%;
    }
    .firstcardbiz  {
        width: 100%;
    }
  }