@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;900&family=Open+Sans:wght@700&family=Roboto:wght@300;500&display=swap');

*,
::after,
::before{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* colors */
:root{
    --Deep-green: #164B2E;
    --Light-green: #2D995F; 
    --Gray:#f2f3f3;
    --White: #ffffff;
    --Black: #343A40;
}
.Btns{
  border-radius: 0;
}
.newBody{
  padding: 0 2rem 0 19rem;
}
.wrapper{
  padding-top: 20px;
}
.contentWrapper{
  background-color: var(--White);
  padding: 20px;
}
.newCreate h4{
font-size: 20px;
font-weight: 700;
}
.backBtn{
  font-size: 15px;
  color: white;
  font-weight: 700;
  cursor: pointer;
  background-color: #2D995F;
  text-align: center;
  padding: 10px 10px 10px 10px;
  border-radius: 4px;
}
.backBtn:hover{
  background-color: #0c4225;

}