:root{
    --white:  #ffffff;
    --light-blue:#DDF3E7;
    --green:#2D995F;
    --text:#6C757D;
    --black:#000;
}
.img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.emailText{
    color: #2D995F;
    font-weight: 500;
}
.footerBody{
    width: 100%;
    padding: 34px 0 34px 90px;
    /* height: 491px; */
    background-color: var(--black);
}
.footerCont{
    display: flex;
    justify-content: start;
    gap: 176px;
    align-items: start;

}
.footerlist{
    display: flex;
    flex-direction: column;
    justify-content: start;
    columns: var(--white) !important;
}
.footerlist h4{
    font-size: 20px;
    font-weight: 700;
    color: var(--white);
    padding-bottom: 36px;
}
.footerlist p{
    font-size: 16px !important;
    font-weight: 400 !important;
    color: var(--white) !important;
    
}
.footerLogo{
    width: 458px;
    height: 204px;
    overflow: hidden;
}
.footerNav{
    cursor: pointer;
}
.footerNav:hover{
    color: var(--green) !important;
}
@media(max-width:800px){
    
    .footerBody{
        width: 100%;
        padding: 65px 23px;
        height: 100%;
        /* background-color: var(--black); */
    }
    .footerCont{
        display: flex;
        flex-direction: column;
        gap: 52px;
        align-items: start;
    
    }
    .footerlist{
        /* display: flex; */
        /* flex-direction: column; */
        /* justify-content: start; */
        /* color: var(--white) !important; */
    }
    .footerlist h4{
        font-size: 18px;
        font-weight: bold;
        /* color: var(--white); */
        padding-bottom: 28px;
    }
    .footerlist p{
        font-size: 12px !important;
        line-height: 34px;
    }
    .footerLogo{
        width: 296px;
        height: 138px;
        overflow: hidden;
        /* margin-bottom: 52px; */
    }
}