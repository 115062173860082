*,
::after,
::before{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* colors */
:root{
    --Deep-green: #164B2E;
    --Light-green: #2D995F; 
    --Gray:#f2f3f3;
    --White: #ffffff;
    --Black: #343A40;
    --Background:rgba(172, 231, 199, 0.05);
}
.regBody{
    width: 100%;
    /* height: 100vh; */
    background-color: var(--Gray);
}
.logoCont{
    width: 100%;
    max-width: 228px;
    height: 77px;
    overflow: hidden;
    margin-bottom: 48px;
}
.img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.regContainer{
    display: flex;
    justify-content: space-between;
    /* grid-template-columns: 25% 75%; */
    /* gap: 30px; */
}
.finishedbodyCont{
  width: 100%;
  padding-left: 20rem;
  padding-right: 5rem;

}
.formSecCont{
  padding: 24px 32px;
  background-color: var(--White);
  border-radius: 12px;
  margin-bottom: 20px;
 
}
.formSecCont h3{
  color: #343A40;
  font-size: 20px;
  font-weight: 700;
}

.formSection{
  width: 100%;
  
}
.mainform{
    width: 100%;
    background-color: var(--White);
    border-radius: 12px;
    /* margin-top: 14rem; */
    display: flex;
    flex-direction: column;   
}

body {
    font-family: 'Inter', sans-serif;
  }
  
  
  .stId{
  
    font-size: 14px;
    padding-bottom: 12px;
    color: #6C757D;
    
    }

    .signin{
        margin-top: 39px;
        margin-left: 19px;
        width: 100%;
    }
  
  .rszeInput {
    display: flex;
    gap: 70px;
    margin-left: 20px;
    padding-right: 3.6rem;
    /* width: 100%; */
    /* justify-content: space-between; */
  }
  
  .formInput{
    margin-top: 30px;
    background-color: #fff !important;
    display: flex;
    flex-direction: column;
  }
  
  .snInput {
    border: 1px solid #ADB5BD;
    border-radius: 4px;
    /* height: 55px; */
      width: 400px;
    margin-bottom: 5px;
    /* padding-left: 10px; */
    outline: none;
    background-color: #f7f8fa !important;
    padding: 10px;
  }
  .snInput2 {
    border: 1px solid #ADB5BD;
    border-radius: 4px;
    height: 100px;
      width: 400px;
    margin-bottom: 5px;
    /* padding-left: 10px; */
    outline: none;
    background-color: #f7f8fa !important;
    padding: 10px;
  }
  .snInput23 {
    border: 1px solid #ADB5BD;
    border-radius: 4px;
    height: 148px;
      width: 400px;
    margin-bottom: 5px;
    /* padding-left: 10px; */
    outline: none;
    background-color: #f7f8fa !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
  }
  
  .formInput1 {
    display: flex;
    flex-direction: column;
  }
  
  .fileContainer {
    display: flex;
    align-items: center;
    cursor: pointer !important;
  }
  
  .fileInputLabel {
    position: relative;
    display: inline-block;
    cursor: pointer !important;
  }
  
  .fileInput {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer !important;
    
  }
  
  .fileIcon {
    width: 24px; /* Adjust as needed */
    height: 24px; /* Adjust as needed */
    margin-right: 8px; /* Adjust as needed */
    cursor: pointer !important;
  }
  
  .placeholder {
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
  padding-bottom: 12px;
  color: #6C757D;
  }
  
  
  .nextKin{
    margin-top: 50px;
    color: #2D995F;
    font-weight: 700;
    font-size: 20px;
    margin-left: 20px;
  }
  
  .continueButton{
    width: 400px;
    height: 55px;
    background-color: #2D995F;
    color: white;
    margin-top: 30px;
    cursor: pointer;
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 66px;
    margin-left: 20px;
    margin-top: 57px;
    font-size: 16px;
    font-weight: 700;
  text-align: center;
  }
  
  .continueReg{
    color: white;
    font-size: 16px;
    font-weight: 700;
  text-align: center;

  }

  .errorMess{
    font-size: 15px;
    color: #000;
    font-weight: 500;
  }

  .errorMess1{
    font-size: 15px;
    color: #c21212;
    font-weight: 500;
  }
  /* .shadow {
    box-shadow: 0 0 5px rgb(238, 236, 236);
} */
@media(max-width:800px){
  .finishedbodyCont{
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    overflow: hidden;
  
  }
  .formSecCont{
    padding: 21px 25px;
    background-color: var(--White);
    border-radius: 0;
    margin-bottom: 20px;
   
  }
  .formSecCont h3{
    color: #343A40;
    font-size: 20px;
    font-weight: lighter;
  }

  .mainform{
    border-radius: 0;
    margin-top: 0;
    padding: 5rem 0 0 0;
}
.rszeInput {
  flex-direction: column;
  gap: 24px;
  margin:0;
  padding:0 0 0 10px;
  width: 100%;
  max-width: 90%;
}
.formInput{
  margin-top: 10px;
  background-color: #fff !important;
  display: flex;
  flex-direction: column;
}
.snInput {
    width: 100%;
  margin-bottom: 0;
  outline: none;
}
.snInput2 {
  height: 75px;
    width: 100%;
    max-width:380px;
  margin-bottom: 0;
  padding: 10px;
}
.snInput23 {
  border: 1px solid #ADB5BD;
  border-radius: 4px;
  height: 148px;
    width: 100%;
  
}
.continueButton{
  width: 100%;
  max-width: 87%;
  height: 43px;
  margin: 50px 15px 50px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.continueReg{
  color: white;
  font-size: 16px;
  font-weight: lighter;
text-align: center;
}
.nextKin{
  margin-top: 50px;
  color: var(--Black);
  font-weight: lighter;
  font-size: 14px;
  margin-left: 10px;
  border-bottom: 2px solid var(--Light-green);
  width: 35%;
  padding-bottom: 3px;
}
}



  
  
  