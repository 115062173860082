*,
::after,
::before{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* colors */
:root{
    --Deep-green: #164B2E;
    --Light-green: #2D995F; 
    --Gray:#f2f3f3;
    --White: #ffffff;
    --Black: #343A40;
}


.finishedbodyCont{
  width: 100%;
  padding-left: 19rem;
  /* padding-right: 4rem; */
  overflow: hidden;

}
.topPadding{
  width: 100%;
  padding-top: 1rem;
}
.formSecCont{
  padding: 24px 32px;
  background-color: var(--White);
  /* border-radius: 12px; */
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.formSecCont h3{
  color: #343A40;
  font-size: 20px;
  font-weight: 700;
}


.loandethead{
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 4rem; */
  /* margin-left: 30px; */
}
.loandethead .formLabel h4{
  font-size: 20 px;
  font-weight: 700;
}
.loandethead .formIntBtn{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  gap: 10px;
}
.formIntBtn .btn1{
  background-color: var(--White);
  border: 1px solid rgb(187, 183, 183);
  color: var(--Black);
  font-size: 14px;
  font-weight: 600;
}
.formIntBtn .btn1:hover{
  border: 2px solid var(--Light-green);
  color: var(--Light-green);
}
.formIntBtn .btn2{
  font-size: 14px;
}
.formIntBtn2{
  width: 100%;
  text-align: right;
  display: flex;
  justify-content: right;
  gap: 25px;
  margin-top: 2rem;
}

.loanText {
  font-size: 15px;
}
/* .loanText1 {
  font-size: 15px;
  margin-left: 20px;
} */




.formSection{
    padding: 35px 30px;
}
.formSection p{
    font-size: 14px;
}
.formSection h1{
    color: var(--Light-green);
    font-size: 28px;
    font-weight: 700;
}
.formSecCont{
    padding: 24px 32px;
    background-color: var(--White);
    /* border-radius: 12px; */
    margin-right: 20px  ;
   
}
.formSecCont h3{
  color: #343A40;
  font-size: 20px;
  font-weight: 700;
}
.mainform{
    width: 100%;
    /* background-color: var(--White); */
    border-radius: 12px;
    margin-top: 26px;
    display: flex;
    flex-direction: column;
    padding: 20px 20px 20px 0;
}
.loanResponsive{
  margin-top: 2rem;
  /* display: flex; */
  justify-content: space-between;
  gap: 30px;
  /* align-items: c; */
}
.loanResponsive .banner{
  background-color: var(--White);
  border-radius: 10px;
  padding: 20px;
  max-width: 35%;
  height: 50%;
  box-shadow: 1px -1px  3px rgb(218, 215, 215);
}
.loanResponsive .banner .imgSec{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.imgInput{
  width: 100%;
  border: 1px solid rgb(204, 201, 201);
  border-radius: 5px;
}
.mainImgCont{
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow:3px 3px 10px  rgb(218, 215, 215) ;
}
.loanResponsive .banner .imgSec img{
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.loanResponsive .banner .imgSec .imgCont{
  position: relative;
}
.loanResponsive .banner .imgSec .imgCont span{
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -10px;
  top: -10px;
  text-align: center;
  background-color: var(--White);
  width: 20px;
  height: 20px;
  border-radius: 50px;
  font-size: 12px;
  box-shadow: 1px -1px  20px rgb(196, 193, 193);
  cursor: pointer;
}
.loanResponsive .banner .imgSec .imgCont span i:hover{
  color: green;
}
.loanResponsive .banner p{
  font-size: 14px;
  text-align: center;
}
.loandgrantcards{
  background-color: var(--White);
  width: 100%;
  padding: 20px ;
}
.loanResponsive .mainForm{
  background-color: var(--White);
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  max-width: 100%;
  box-shadow: 1px -1px  3px rgb(218, 215, 215);
}
.mainform .form{
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.mainform .form .span{
  color: #f95f7a;
  background-color:transparent;
  border: none;
}
.mainform .form ::placeholder{
  color: #b0a1bc;
}
.span{
  border: 1px solid rgb(218, 215, 215);
  height: 250px;
  border-radius: 10px;
}
.formActionArea{
  border-bottom: 1px solid rgb(218, 215, 215);
}
.span button, 
.span textarea{
  border: none;
  background-color: transparent;
  outline: none;
}
.span textarea{
  width: 100%;
  height: 150px;
  padding: 10px;
}
.span button:hover{
  background-color: transparent;
  color: #17c653;
} 
.form .editor{
  border: 1px solid rgb(218, 215, 215);
  height: 250px;  
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  /* width: 550px; */
}
.footerCont{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 4rem;
}
.footerCont a{
  text-decoration: none;
  color: #6C757D; 
}
.footerCont a:hover{
  color: green;
}
.footerCont small{
  color: #6C757D;
  font-weight: 600;
}
.footerCont .small{
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
@media(max-width:800px){
  .finishedbodyCont{
    padding-left: 0;
    padding-right: 0;
  
  }
  .formSecCont{
    padding: 21px 25px;
    background-color: var(--White);
    border-radius: 0;
    margin-bottom: 20px;
   
  }
  .formSecCont h3{
    color: #343A40;
    font-size: 20px;
    font-weight: lighter;
  }
  .applygrnttxt {
    font-size: 18px;
    margin-top: 17px;
    margin-bottom: 4px;
  }
  
  .grntapplytxt {
    padding: 0 34px;
    font-size: 14px;
  }
  .applyLoan{
    width: 236px;
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-bottom: 0;
  }
  
  .continueReg{
    /* color: white; */
    /* font-size: 16px; */
    font-weight: 400;
  /* text-align: center; */
  }
}
  
  
